import React from "react";
import Seo from "../components/info-site/Seo";

import GetStarted from "../components/info-site/GetStarted";
import Layout from "../layouts/en";
import Integration from "../components/info-site/Integration";


const Homepage = props => {
    return (
			<Layout location={props.location}>
				<Seo titleId="homepageTitle" descriptionId="homepageDescription" />
				<Integration
          title="integration"
          subtitle="integrationSubtitle"
          contact="integrationContact"
        />
				<GetStarted
          title="getIntegrated"
        />
			</Layout>
		);
};

export default Homepage;